footer {
  display: flex;
  justify-content: space-between;
  padding: 60px 0;
  background-color: rgba(255,255,255,0.75);
  padding-left: 3000px;
  margin-left: -3000px;
  padding-right: 3000px;
  margin-right: -3000px;
}
footer .social-links {
  width: 260px;
}
footer p {
  font-size: 0.8rem;
}
@media(max-width: 656px) {
  footer {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
  footer p {
    padding-top: 30px;
    font-size: 0.65rem;
  }
}