.section-img-two-column {
  display: flex;
  justify-content: space-around;
}

.section-img {
  padding: 0.5rem;
}

.section-img-center > * {
  margin: 0 auto;
  width: 100%;
}
