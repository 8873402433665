/* @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap'); */

html,body {
  margin: 0;
  padding: 0;
  height: 100%; /* needed for container min-height */
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  /* background-color: #FFF8EF; */
  overflow-x: hidden;
}
p, h1, h2, h3, h4, h5 {
  margin: 0;
}

.App {
  height: auto;
  min-height: 100%;
  padding-top: 30px;
}

button {
  margin: 15px 0 0 0;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 0.75rem;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
}
button.primary {
  color: white;
  background-color: salmon;
}
button.primary:hover {
  background-color: #d83c2a;
}
button.secondary {
  color: #24283B;
  background-color: rgba(30, 34, 51, 0.2);
}
button.secondary:hover {
  color: #24283B;
  background-color: rgba(255,255,255,0.75);
}
hr {
  margin: 30px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgba(30, 34, 51, 0.2); 
}

.container {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  max-width: 1100px;
  /* box-shadow: 0px 9px 12px #24283B; */
}
.section {
  display: flex;
  padding: 30px 15px;
  color: #24283B;
}

.section-intro,
.section-showcase,
.section-work,
.section-contact,
.section-how-i-work,
.section-page {
  flex-direction: column;
}

.section-work h2,
.section-contact h2,
.section-showcase h2,
.section-how-i-work h2,
.section-page h2 {
  text-align: center;
  margin-bottom: 45px;
}
@media(max-width: 656px) {
  .section-work h2,
  .section-contact h2,
  .section-showcase h2,
  .section-how-i-work h2,
  .section-page h2 {
    font-size: 1.25rem;
    margin-bottom: 15px;
  }
}

.section.section-page {
  padding: 30px 60px;
}
.section.section-page h1 {
  padding: 30px 0;
}
.section.section-page p {
  padding: 15px 0;
}
.section.section-page p:last-child {
  padding-bottom: 45px;
}
.section.section-page p a {
  color: salmon;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 2px solid transparent;
}
.section.section-page p a:hover {
  padding-bottom: 2px;
  border-bottom: 2px solid salmon;
}
.section-page .prominent {
  font-weight: 100;
  letter-spacing: -1px;
  font-size: 1.25rem;
}
.section-page .tech span {
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.8rem;
  color: #FFF8EF;
  background-color: salmon;
  border-radius: 5px;
}
