.section.section-how-i-work {
  padding: 30px 60px;
}
.section.section-how-i-work h4 {
  padding-bottom: 5px;
}
.section.section-how-i-work h4 svg {
  padding-right: 5px;
  color: salmon;
}
.section.section-how-i-work .smiley {
  position: absolute;
  margin-top: -3px;
  margin-left: 7px;
  font-size: 1.25rem;
}
@media(max-width: 656px) {
  .section.section-how-i-work {
    padding: 30px;
  }
}
